.subasta-add-system-container {
  width: 100%;
  height: 100px;
  background-color: #9b9b9b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a4a4a;
  font-size: 26px;
  font-weight: bold;
  display: none;
}

@media (max-width: 768px) {
  .subasta-add-system-container {
    height: 42px;
    background-color: #092b18;
    color: #9a9a9a;
    font-size: 18px;
    display: none;
  }
}
