.profileCloseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: fixed;
    top: -18px;
    right: 18px;
}

.profileContainer {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    Background: #00612d;
    top: -2px;
    left: -2px;
    border: 2px solid;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: scroll;
}

.profileContainer h1 {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    width: 100%;
}

.profileDataContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    gap: 10px;
}

.profileWinningsContainer {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
}

.profileWinningSlot {
    border: 5px solid black;
    display: flex;
    flex-direction: column;
    max-width: 30%;
    padding: 10px;
    background: rgb(27, 81, 1);
    background: radial-gradient(circle, rgba(27, 81, 1, 1) 0%, rgba(0, 97, 45, 1) 35%, rgba(0, 19, 9, 1) 100%);
}

.trophyContainer {
    width: 100%;
}

.trophyContainer img {
    display: block;
    width: 50%;
    object-fit: contain;
    margin: 0 auto;
}

.winningDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: goldenrod;
    color: black;
    /* width: 95%; */
    font-size: 10px;
    margin: 5px auto 0 auto;
    padding: 5px;
}

.winningDataItem {
    margin-bottom: 10px;
}