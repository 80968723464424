.subasta-nav-inf-container {
  position: fixed;
  bottom: 0;
  background-color: #00612ddd;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subasta-nav-inf-icons-container {
  display: block;
  margin: 0 auto;
}

.subasta-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.subasta-nav-inf-icon-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  margin: 0 20px;
}

.subasta-nav-item h4 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.subasta-room-icon {
  width: 42px;
}

.subasta-nav-sup-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 20%;
}

.logout {
  display: block;
  float: right;
  color: white;
  margin-right: 10px;
}