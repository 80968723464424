.subasta-loader-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url("../../images/loginBg.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.subasta-loading-container {
  width: 250px;
  height: 400px;
  background-color: white;
  border: 3px solid #00612d;
  border-radius: 15px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subasta-loading-img {
  margin: 0 auto;
  text-align: center;
  width: 200px;
  box-sizing: border-box;
}

.subasta-loading-img img {
  display: block;
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.subasta-loading-text {
  display: inline-block;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.subasta-loading-text h3 {
  font-family: "Oswald", sans-serif;
  color: #00612d;
  font-weight: bold;
  font-size: 20px;
}
