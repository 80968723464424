.room-preview-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 500;
}

.room-preview-message-container {
  width: 80%;
  background-color: #ffffff;
  border: 2px solid #00612d;
  border-radius: 13px;
  text-align: center;
  margin: 0 auto 10px auto;
  padding: 20px;
}

.btn-primary a {
  color: white;
  text-decoration: none;
}

.subasta-room-screen-container {
  width: 100%;
  height: 100vh;
  background-image: url("../../images/createRoomBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.subasta-room-btn-container .btn-danger-small {
  width: 30px;
  position: absolute;
  font-size: 18px;
  left: 20px;
  height: 30px !important;
  background-color: transparent;
}

.subasta-room-btn-container .btn img {
  width: 20px;
  height: 20px;
}

.subasta-room-screen-container h1 {
  color: white;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  font-weight: 500;
  margin: 5px 0;
}

.subasta-room-screen-container h2 {
  color: #ffa700;
  font-family: "Oswald", sans-serif;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-top: 0px;
  margin-bottom: 10px;
}

.room-matchDate-title {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 20px 0px;
}

.room-matchDate-date {
  color: #f2983b;
  font-family: "Oswald", sans-serif;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 20px;
}

.subasta-room-screen-options-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subasta-room-screen-options-menu-item {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fded00;
  background-color: #fded00;
  border-radius: 50px;
  margin: 5px 15px;
  cursor: pointer;
  color: white;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.6);
}

.subasta-room-screen-options-menu-item span {
  color: #092b18;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.item-plus {
  border: 2px solid #ffa700;
  background-color: #ffa700;
}

.subasta-room-screen-options-menu-users-item {
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  cursor: pointer;
  color: white;
}

.subasta-room-screen-options-menu-item:hover,
.subasta-room-screen-options-menu-item:active {
  box-shadow: none;
}

.subasta-room-screen-options-text {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #092b18;
}

.subasta-room-screen-grid-container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-areas:
    "visitorTeam localTeam"
    "visitorTeam score-grid-container";
  width: 70%;
  margin: 5px auto;
  text-align: center;
}

.localTeam {
  grid-area: localTeam;
  font-family: "Oswald", sans-serif;
  color: #fded00;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 30px;
}

.outer {
  width: 30px;
  position: relative;
  display: inline-block;
  height: 100%;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-top: 30px;
}

.rotate {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.visitorTeam {
  grid-area: visitorTeam;
  font-family: "Oswald", sans-serif;
  color: #fded00;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
}

.screen-grid-container {
  grid-area: screen-grid-container;
}

.subasta-room-screen-score-grid {
  display: grid;
  grid-template-columns: 30px repeat(6, 1fr);
  grid-template-areas:
    ". scoreTitleX0 scoreTitleX1 scoreTitleX2 scoreTitleX3 scoreTitleX4 scoreTitleX5"
    "scoreTitleY0 scoreL0V0 scoreL1V0 scoreL2V0 scoreL3V0 scoreL4V0 scoreL5V0"
    "scoreTitleY1 scoreL0V1 scoreL1V1 scoreL2V1 scoreL3V1 scoreL4V1 scoreL5V1"
    "scoreTitleY2 scoreL0V2 scoreL1V2 scoreL2V2 scoreL3V2 scoreL4V2 scoreL5V2"
    "scoreTitleY3 scoreL0V3 scoreL1V3 scoreL2V3 scoreL3V3 scoreL4V3 scoreL5V3"
    "scoreTitleY4 scoreL0V4 scoreL1V4 scoreL2V4 scoreL3V4 scoreL4V4 scoreL5V4"
    "scoreTitleY5 scoreL0V5 scoreL1V5 scoreL2V5 scoreL3V5 scoreL4V5 scoreL5V5";
  grid-gap: 0.25rem;
}

.scoreTitleX,
.scoreTitleY {
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  height: 35px;
}

.scoreTitleY {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 50px;
  justify-self: end;
}

.scoreTitleX0 {
  grid-area: scoreTitleX0;
}

.scoreTitleX1 {
  grid-area: scoreTitleX1;
}

.scoreTitleX2 {
  grid-area: scoreTitleX2;
}

.scoreTitleX3 {
  grid-area: scoreTitleX3;
}

.scoreTitleX4 {
  grid-area: scoreTitleX4;
}

.scoreTitleX5 {
  grid-area: scoreTitleX5;
}

.scoreTitleY0 {
  grid-area: scoreTitleY0;
}

.scoreTitleY1 {
  grid-area: scoreTitleY1;
}

.scoreTitleY2 {
  grid-area: scoreTitleY2;
}

.scoreTitleY3 {
  grid-area: scoreTitleY3;
}

.scoreTitleY4 {
  grid-area: scoreTitleY4;
}

.scoreTitleY5 {
  grid-area: scoreTitleY5;
}

.score-box {
  border: 1px solid #00612d;
  background-color: white;
  border-radius: 9px;
}

.scoreL0V0::after,
.scoreL0V1::after,
.scoreL0V2::after,
.scoreL0V3::after,
.scoreL0V4::after,
.scoreL0V5::after,
.scoreL1V0::after,
.scoreL1V1::after,
.scoreL1V2::after,
.scoreL1V3::after,
.scoreL1V4::after,
.scoreL1V5::after,
.scoreL2V0::after,
.scoreL2V1::after,
.scoreL2V2::after,
.scoreL2V3::after,
.scoreL2V4::after,
.scoreL2V5::after,
.scoreL3V0::after,
.scoreL3V1::after,
.scoreL3V2::after,
.scoreL3V3::after,
.scoreL3V4::after,
.scoreL3V5::after,
.scoreL4V0::after,
.scoreL4V1::after,
.scoreL4V2::after,
.scoreL4V3::after,
.scoreL4V4::after,
.scoreL4V5::after,
.scoreL5V0::after,
.scoreL5V1::after,
.scoreL5V2::after,
.scoreL5V3::after,
.scoreL5V4::after,
.scoreL5V5::after {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.1);
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.scoreL0V0 {
  grid-area: scoreL0V0;
  position: relative;
}

.scoreL0V0::after {
  content: "0-0";
  position: absolute;
  top: 0;
}

.scoreL0V1 {
  grid-area: scoreL0V1;
  position: relative;
}

.scoreL0V1::after {
  content: "0-1";
  position: absolute;
  top: 0;
}

.scoreL0V2 {
  grid-area: scoreL0V2;
  position: relative;
}

.scoreL0V2::after {
  content: "0-2";
  position: absolute;
  top: 0;
}

.scoreL0V3 {
  grid-area: scoreL0V3;
  position: relative;
}

.scoreL0V3::after {
  content: "0-3";
  position: absolute;
  top: 0;
}

.scoreL0V4 {
  grid-area: scoreL0V4;
  position: relative;
}

.scoreL0V4::after {
  content: "0-4";
  position: absolute;
  top: 0;
}

.scoreL0V5 {
  grid-area: scoreL0V5;
  position: relative;
}

.scoreL0V5::after {
  content: "0-5";
  position: absolute;
  top: 0;
}

.scoreL1V0 {
  grid-area: scoreL1V0;
  position: relative;
}

.scoreL1V0::after {
  content: "1-0";
  position: absolute;
  top: 0;
}

.scoreL1V1 {
  grid-area: scoreL1V1;
  position: relative;
}

.scoreL1V1::after {
  content: "1-1";
  position: absolute;
  top: 0;
}

.scoreL1V2 {
  grid-area: scoreL1V2;
  position: relative;
}

.scoreL1V2::after {
  content: "1-2";
  position: absolute;
  top: 0;
}

.scoreL1V3 {
  grid-area: scoreL1V3;
  position: relative;
}

.scoreL1V3::after {
  content: "1-3";
  position: absolute;
  top: 0;
}

.scoreL1V4 {
  grid-area: scoreL1V4;
  position: relative;
}

.scoreL1V4::after {
  content: "1-4";
  position: absolute;
  top: 0;
}

.scoreL1V5 {
  grid-area: scoreL1V5;
  position: relative;
}

.scoreL1V5::after {
  content: "1-5";
  position: absolute;
  top: 0;
}

.scoreL2V0 {
  grid-area: scoreL2V0;
  position: relative;
}

.scoreL2V0::after {
  content: "2-0";
  position: absolute;
  top: 0;
}

.scoreL2V1 {
  grid-area: scoreL2V1;
  position: relative;
}

.scoreL2V1::after {
  content: "2-1";
  position: absolute;
  top: 0;
}

.scoreL2V2 {
  grid-area: scoreL2V2;
  position: relative;
}

.scoreL2V2::after {
  content: "2-2";
  position: absolute;
  top: 0;
}

.scoreL2V3 {
  grid-area: scoreL2V3;
  position: relative;
}

.scoreL2V3::after {
  content: "2-3";
  position: absolute;
  top: 0;
}

.scoreL2V4 {
  grid-area: scoreL2V4;
  position: relative;
}

.scoreL2V4::after {
  content: "2-4";
  position: absolute;
  top: 0;
}

.scoreL2V5 {
  grid-area: scoreL2V5;
  position: relative;
}

.scoreL2V5::after {
  content: "2-5";
  position: absolute;
  top: 0;
}

.scoreL3V0 {
  grid-area: scoreL3V0;
  position: relative;
}

.scoreL3V0::after {
  content: "3-0";
  position: absolute;
  top: 0;
}

.scoreL3V1 {
  grid-area: scoreL3V1;
  position: relative;
}

.scoreL3V1::after {
  content: "3-1";
  position: absolute;
  top: 0;
}

.scoreL3V2 {
  grid-area: scoreL3V2;
  position: relative;
}

.scoreL3V2::after {
  content: "3-2";
  position: absolute;
  top: 0;
}

.scoreL3V3 {
  grid-area: scoreL3V3;
  position: relative;
}

.scoreL3V3::after {
  content: "3-3";
  position: absolute;
  top: 0;
}

.scoreL3V4 {
  grid-area: scoreL3V4;
  position: relative;
}

.scoreL3V4::after {
  content: "3-4";
  position: absolute;
  top: 0;
}

.scoreL3V5 {
  grid-area: scoreL3V5;
  position: relative;
}

.scoreL3V5::after {
  content: "3-5";
  position: absolute;
  top: 0;
}

.scoreL4V0 {
  grid-area: scoreL4V0;
  position: relative;
}

.scoreL4V0::after {
  content: "4-0";
  position: absolute;
  top: 0;
}

.scoreL4V1 {
  grid-area: scoreL4V1;
  position: relative;
}

.scoreL4V1::after {
  content: "4-1";
  position: absolute;
  top: 0;
}

.scoreL4V2 {
  grid-area: scoreL4V2;
  position: relative;
}

.scoreL4V2::after {
  content: "4-2";
  position: absolute;
  top: 0;
}

.scoreL4V3 {
  grid-area: scoreL4V3;
  position: relative;
}

.scoreL4V3::after {
  content: "4-3";
  position: absolute;
  top: 0;
}

.scoreL4V4 {
  grid-area: scoreL4V4;
  position: relative;
}

.scoreL4V4::after {
  content: "4-4";
  position: absolute;
  top: 0;
}

.scoreL4V5 {
  grid-area: scoreL4V5;
  position: relative;
}

.scoreL4V5::after {
  content: "4-5";
  position: absolute;
  top: 0;
}

.scoreL5V0 {
  grid-area: scoreL5V0;
  position: relative;
}

.scoreL5V0::after {
  content: "5-0";
  position: absolute;
  top: 0;
}

.scoreL5V1 {
  grid-area: scoreL5V1;
  position: relative;
}

.scoreL5V1::after {
  content: "5-1";
  position: absolute;
  top: 0;
}

.scoreL5V2 {
  grid-area: scoreL5V2;
  position: relative;
}

.scoreL5V2::after {
  content: "5-2";
  position: absolute;
  top: 0;
}

.scoreL5V3 {
  grid-area: scoreL5V3;
  position: relative;
}

.scoreL5V3::after {
  content: "5-3";
  position: absolute;
  top: 0;
}

.scoreL5V4 {
  grid-area: scoreL5V4;
  position: relative;
}

.scoreL5V4::after {
  content: "5-4";
  position: absolute;
  top: 0;
}

.scoreL5V5 {
  grid-area: scoreL5V5;
  position: relative;
}

.scoreL5V5::after {
  content: "5-5";
  position: absolute;
  top: 0;
  z-index: 200;
}

.subasta-scoreOffering-offerGrid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f2983b;
  font-family: "Oswald", sans-serif;
  border-radius: 6px;
  /* z-index: 200; */
}

.subasta-scoreOffering-offerGrid-OfferPlayerName {
  font-size: 10px;
}

.subasta-scoreOffering-offerGrid-offersByScore {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}

.subasta-scoreOffering-offerGrid-offersByScore img {
  height: 80%;
}

.subasta-room-screen-total-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  margin: 0 auto;
  padding: 10px 0;
  font-size: 18px;
}

.subasta-room-screen-total-wrap {
  display: flex;
  flex-direction: column;
}

.subasta-room-screen-total-value-number {
  font-size: 20px;
  font-weight: 700;
}

.subasta-room-screen-total-help-icon {
  display: block;
  margin-right: 10px;
  cursor: pointer;
}

#textToCopy {
  position: absolute;
  opacity: 0;
  top: 0;
}

.swal2-content h3 {
  margin: 5px auto;
}

.helpIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fded00;
  border-radius: 58px;
  margin: 3px 0;
  cursor: pointer;
  transition: ease-in-out all 300ms;
}

.helpIcon:hover {
  background-color: #f2983b;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
  .subasta-room-screen-grid-container {
    width: 90%;
  }

  .score-box::after {
    font-size: 18px;
  }

  .subasta-scoreOffering-offerGrid-container {
    font-size: 12px;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

a {
  text-decoration: none;
}

.col {
  width: 45%;
}

@media (max-width: 768px) {
  .col {
    font-size: 14px !important;
  }
}

.subasta-help {
  padding: 0;
}

.subasta-help li {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  font-size: 13px;
  padding-bottom: 6px;
}

.subasta-help li:before {
  content: url("../../images/futbol-list-icon.png");
  margin-right: 5px;
}

.subasta-help li .subasta-room-screen-options-menu-item {
  width: 20px;
  height: 20px;
}

.subasta-help li .subasta-room-screen-options-menu-item img {
  width: 60%;
}

.swal2-content h4 {
  margin: 10px 0px;
}

.fullMember {
  font-size: 14px;
  text-align: center;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 10px;
}

.fullMember p {
  margin-bottom: 10px;
}

.btn-members {
  display: block;
  margin: 0 auto;
  background-color: #00612d;
  color: white !important;
  font-family: "Oswald", sans-serif !important;
  font-weight: bold !important;
  font-size: 18px;
  padding: 0 15px;
}

.table-desc {
  font-size: 12px;
}

.playerListTable {
  display: flex;
  text-align: center;
  font-size: 14px;
}


.playerListTable tbody {
  width: 100%;
}

.playerListTable tr {
  display: flex;
  align-items: self-start;
}

.playerListTable th,
.playerListTable td {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.playerListTable th {
  padding-bottom: 20px;
  flex-direction: column;
}

.table-small-text {
  font-size: 12px;
  font-weight: 300;
}