.subasta-roomslist-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px auto;
  padding-bottom: 30px;
}

.subasta-invited-roomslist-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px auto;
  padding-bottom: 90px;
}
