.subasta-nav-sup-container {
  position: relative;
  background-color: #00612d;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subasta-nav-sup-logo {
  width: 80%;
  text-align: center;
  margin-left: 15%;
}

.subasta-nav-sup-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 20%;
}

.logout {
  display: block;
  float: right;
  color: white;
  margin-right: 10px;
}

.subastaMenu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 300;
}

.subastaMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 60vh;
  top: 70px;
  left: 50%;
  background-color: #00612d;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 2px solid white;
  border-radius: 7px;
}

.subastaMenu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 90%;
}

.subastaMenu-closeBtn {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -4%;
}

.closeBtn {
  color: white;
  background-color: #e94148;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.subastaMenu-link a,
.subastaMenu-link-button {
  color: white;
  font-size: 22px;
  font-family: "Oswald", sans-serif;
  text-decoration: none;
  font-weight: 600;
  transition: 200ms ease-in all;
  background-color: transparent;
  border: none;
}

.subastaMenu-link a:hover,
.subastaMenu-link-button:hover {
  color: #ffa800;
  text-decoration: underline;
}

.rankingListContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: -2px;
  left: -2px;
  background-color: #00612d;
  padding-bottom: 30px;
  border-radius: 3px;
  border: 2px solid white;
  overflow: hidden;
  overflow-y: scroll;
}

.ranking-closeBtn {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-ranking {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 1px solid #ffa700;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.btn-ranking:hover {

  background-color: #ffa700;
  color: #00612d;

}

.rankingListContainer h1 {
  font-size: 48px;
  font-family: "Oswald", sans-serif;
  color: #ffa800;
}

.rankingListContainer {
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  color: white;
}

.rankingList {
  width: 90%;
}

.ranking-headings {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 20px 0;
}

.ranking-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rankingRow {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.position-title {
  width: 20%;
  font-weight: bold;
  text-align: right;
}

.name-title {
  width: 50%;
  font-weight: bold;
  text-align: center;
}

.winningMatchs-title {
  width: 30%;
  font-weight: bold;
  text-align: left;
}

.positionNumber {
  width: 20%;
  text-align: right;
}

.nameText {
  width: 50%;
  text-align: center;
}

.winningMatchNumber {
  width: 30%;
  text-align: center;
}

@media (max-width: 768px) {
  .subastaMenu {
    width: 80vw;
  }

  .subastaMenu-closeBtn {
    margin-top: -10%;
  }
}