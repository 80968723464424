.recovery-code-form-container {
  width: 60%;
  margin-top: 50px;
  text-align: center;
}

.recovery-code-form-container p {
  color: red;
}

.recovery-code-form label {
  display: block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.recovery-code-form input[type="text"] {
  display: block;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  height: 54px;
  background-color: #f8faf5;
  border: 1px solid rgba(0, 97, 45, 0.4);
  border-radius: 9px;
  font-family: "Roboto", sans-serif;
  font-size: 54px;
  color: #4a4a4a;
  margin-bottom: 10px;
  line-height: 50px;
  margin-bottom: 20px;
}
