.subasta-dashboard-container {
  position: relative;
  background-image: url("../../images/loginBg.jpg");
  background-size: contain;
  background-repeat: repeat-y;
  width: 100%;
}

.subasta-user-section {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.subasta-create-room-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subasta-create-room-btn-container h4 {
  cursor: pointer;
  font-size: 16px;
  margin: 5px 0;
}

.create-room-btn,
.search-room-btn {
  background-color: #ffa700;
  border: 3px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.create-room-btn img {
  width: 42px;
  height: 42px;
}

.search-room-btn img {
  width: 34px;
  height: 35px;
}

.subasta-user-card-container {
  display: block;
  text-align: center;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 13px;
  border-left: 14px solid #ffa700;
  width: 405px;
}

.subasta-user-card-first-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #ffa700;
  height: 58px;
}

.subasta-user-card-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subasta-user-card-text {
  font-size: 22px;
  font-weight: bold;
  color: #4a4a4a;
  line-height: 32px;
}

.subasta-user-ranking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.subasta-ranking-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subasta-ranking-container svg {
  width: 15px;
  fill: #979797;
  margin-left: 5px;
}

.subasta-ranking-container svg:hover {
  width: 15px;
  fill: #ffa700;
  margin-left: 5px;
}

.subasta-trophy-container svg {
  width: 25px;
  fill: #979797;
  margin-left: 5px;
}

.subasta-trophy-container svg:hover {
  width: 25px;
  fill: #ffa700;
  margin-left: 5px;
}

.subasta-user-card-ranking-value {
  font-size: 26px;
  font-weight: bold;
  color: #ffa700;
}

.subasta-user-card-second-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 58px;
}

.subasta-key-icon-container {
  margin-left: 20px;
}

.subasta-user-card-key-ammount {
  font-size: 36px;
  font-weight: bold;
  color: #ffa700;
  line-height: 36px;
  margin-left: 10px;
}

.subasta-user-card-btn-container {
  display: flex;
  width: 130px;
  align-items: center;
  justify-content: space-around;
  background-color: #ffa700;
  border: 1px solid #788687;
  border-radius: 23px;
  font-size: 12px;
  height: 30px;
  padding: 0 15px;
  color: white;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.26);
}

.subasta-user-card-key-btn a {
  color: white;
  text-decoration: none;
}

.subasta-createRoomsMenu-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: scroll;
}

.ranking-btn-container {
  width: 90%;
  display: flex;
}

.btn-ranking {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 1px solid #ffa700;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.btn-ranking:hover {

  background-color: #ffa700;
  color: #00612d;

}

.trophies-home-container {
  position: absolute;
  background-color: rgb(0, 97, 45);
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 7vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .subasta-user-section {
    width: 100%;
    margin-top: 20px;
  }

  .subasta-user-card-container {
    width: 80%;
    margin-bottom: 20px;
  }

  .subasta-user-card-text {
    font-size: 14px;
    font-weight: 400;
    line-height: initial;
    letter-spacing: 0;
  }

  /* .subasta-createRoomsMenu-container {
    position: fixed;
  } */
}