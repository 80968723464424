.create-room-container {
  width: 450px;
  border: 2px solid #00612d;
  border-radius: 18px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
  background-image: url("../../images/createRoomBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 100px 0 0 106px;
  /* min-height: 700px;
  overflow-x: hidden;
  overflow-y: scroll; */
}

.create-room-title {
  font-size: 28px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #ffcc00;
  text-align: center;
  text-transform: uppercase;
}
.create-room-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-room-icon-container img {
  width: 50px;
  margin-bottom: 10px;
  text-align: center;
}

.create-room-icon-title {
  font-size: 12px;
  color: #f5a623;
}

.create-room-description {
  color: white;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  padding: 10px 20px;
}

.create-room-form-container {
  height: 90vh;
  margin-bottom: -30px;
}

.create-room-form {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
}

.create-room-form label,
.create-room-match-data-title {
  font-size: 14px;
  font-weight: 400;
  color: #fded00;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.create-room-form input,
.create-room-form select,
.create-room-form-select__control {
  -webkit-appearance: none;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #00612d;
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid #00612d;
  height: 50px;
  line-height: 50px;
  padding-left: 10px;
  border-radius: 9px;
  box-sizing: border-box;
}

.create-room-form-select-container,
.create-room-form-select-match-container {
  height: 35px;
  margin-bottom: 20px;
}

.create-room-form-select__value-container,
.create-room-form-select-match__value-container {
  padding: 0 10px;
  height: 35px;
}

.create-room-form-select__placeholder,
.create-room-form-select-match__placeholder {
  color: #00612d !important;
  height: 35px !important;
  line-height: 35px !important;
  top: -7px !important;
  position: relative !important;
  transform: initial !important;
  margin: 0 !important;
}

.create-room-form-select__single-value,
.create-room-form-select-match__single-value {
  position: relative !important;
  top: -7px !important;
  line-height: 35px !important;
  color: #00612d !important;
  margin: 0 !important;
  padding: 0 !important;
  transform: initial !important;
}

.create-room-match-data-info-container,
.create-room-match-data-time-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 20px 0px;
}

.hour-container,
.unit-container {
  width: 100%;
  text-align: center;
}

.unit-container input {
  width: 80%;
  font-size: 22px;
  text-align: right;
  padding-right: 20px;
}

.unit-container input::placeholder {
  color: #676767;
}

.full-time-container {
  display: flex;
  justify-content: center;
}

.create-room-match-data-text {
  color: white;
  font-size: 22px;
}

.create-room-match-data-hour-container {
  width: 100%;
  font-size: 24px;
  text-align: center;
  margin: 0 auto !important;
}
.create-room-match-data-separator-container {
  font-size: 36px;
  font-weight: bold;
  color: white;
  line-height: 50px;
  align-self: flex-start;
  margin: 0 10px;
}

.create-room-btn-container {
  margin: 10px 10px 0 auto;
  width: fit-content;
}

.helpIcon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.helpIcon-container label {
  margin-bottom: 0;
}

.subasta-create-rooms-btn-container {
  height: 10px;
}

.subasta-create-rooms-btn-container .btn-danger-small {
  width: 30px;
  position: absolute;
  font-size: 18px;
  right: 20px;
  border: 2px solid #e94148;
  border-radius: 28px;
  height: 30px !important;
}

@media (max-width: 768px) {
  .create-room-container {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0 0 0;
    height: 100vh;
    overflow: scroll;
  }

  .createRoom {
    width: 50% !important;
    font-size: 20px !important;
  }
}
