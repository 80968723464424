.login-form {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 575px) {
  .login-form {
    overflow: hidden;
    overflow-y: scroll;
    height: 400px;
    margin-bottom: 100px;
  }
}

.login-form label {
  display: block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.login-form input[type="text"],
.login-form input[type="email"],
.login-form input[type="password"] {
  display: block;
  margin: 0 auto;
  width: 75%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 97, 45, 0.4);
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 10px;
  padding-left: 10px;
  line-height: 40px;
}

.login-form p {
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin: 10px 0;
}

.login-form a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.registerLink {
  color: #055a1c;
  font-size: 28px !important;
  font-family: "Oswald", sans-serif !important;
  font-weight: 600 !important;
}

@media (max-width: 575px) {
  .registerLink {
    font-size: 18px !important;
  }
}

.center-button {
  text-align: center;
  margin: 0 auto;
}

.subasta-separator-title-gray {
  color: #131313;
  font-weight: 300;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
  background-color: #cacaca;
  padding: 10px 0;
}

.subasta-separator-title {
  color: #131313;
  font-weight: 300;
  font-size: 16px;
  margin: 16px auto;
  text-align: center;
  background-color: transparent;
  padding: 10px 0;
}

@media (max-width: 575px) {
  .subasta-separator-title {
    font-size: 14px;
    margin: 0px auto;
  }
}

.login-signup-accept-terms {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding-top: 20px;
}

.login-signup-accept-terms p {
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.login-signup-accept-terms a {
  text-decoration: underline;
}

.subasta-pass-input {
  display: flex;
  width: 73%;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  border: 1px solid rgba(0, 97, 45, 0.4);
  border-radius: 30px;
  padding: 0 10px;
}

.subasta-pass-field {
  border: 1px solid transparent !important;
  margin-bottom: 0 !important;
  width: 90% !important;
}

.subasta-pass-input-icon {
  width: 10%;
}

.subasta-pass-input-icon img {
  width: 36px;
}