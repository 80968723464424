.trophiesCloseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 18px;
    right: 18px;
}

.trophiesContainer {
    display: block;

    width: 100%;
    height: 100%;
    color: white;
    Background: #00612d;
}

.trophiesContainer h1 {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    width: 100%;
}

.trophiesWinningsContainer {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
}

.trophyWinningSlot {
    border: 5px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 40%;
    padding: 10px;
    background: rgb(27, 81, 1);
    background: radial-gradient(circle, rgba(27, 81, 1, 1) 0%, rgba(0, 97, 45, 1) 35%, rgba(0, 19, 9, 1) 100%);
}

.trophyContainer {
    width: 100%;
}

.trophyContainer img {
    display: block;
    width: 50%;
    object-fit: contain;
    margin: 0 auto;
}

.trophyDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: goldenrod;
    color: black;
    width: 95%;
    font-size: 10px;
    margin: 5px auto 0 auto;
    padding: 5px;
}

.trophyDataItem {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}